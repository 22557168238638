import * as React from 'react';
import { Table } from '@jsluna/table';
import { IDivisionPledgeByDateSummary, IDivisionPledgeSummary, IDivisionSummaryReport } from '../../../models/reporting/IDivisionSummaryReport';
import { GridItem, GridWrapper } from '@jsluna/grid';
import { ButtonGroupPrimary, ButtonGroupSecondary, ButtonGroupWrapper, FilledButton, OutlinedButton } from '@jsluna/button';
import { Modal, ModalHeading } from '@jsluna/modal';
import {
    TableContainer,
    TableHeader,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
} from '@jsluna/table';
import { Checkbox } from '@jsluna/form';

interface IDivisionSummaryData {
    [key: string]: any;
}

interface IDivisionSummaryProps {
    report: IDivisionSummaryReport;
    CSVDownloader: any;
}

interface IDivisionSummaryState {
    showDownloadModel: boolean;
    allDivisionsSelected: boolean;
    selectedDivisions: string[]
}

export class DivisionSummary extends React.Component<IDivisionSummaryProps, IDivisionSummaryState> {

    constructor(props: IDivisionSummaryProps) {
        super(props);

        this.state = {
            showDownloadModel: false,
            allDivisionsSelected: true,
            selectedDivisions: Array.from(new Set(this.props.report.divisionPledgeSummary.map(p => p.division)))
        };
    }

    private reportData: IDivisionSummaryData[] = [];

    public render(): React.ReactNode {        
        const columns = this.getReportTableColumns(this.props.report);
        this.reportData = this.getReportTableData(this.props.report);
        return <>
            {
                this.downloadModel()
            }
            <GridWrapper>
                <GridItem key="actionbar" size="1/1" element="div">
                    <ButtonGroupSecondary>
                        <FilledButton onClick={()=>{
                            this.setState({
                                showDownloadModel: true
                            });
                        }}>
                            Download Report
                        </FilledButton>                        
                    </ButtonGroupSecondary>
                </GridItem>
                <GridItem size="1/1" element="div">
                    <Table
                        rowKey="division"
                        columns={columns}
                        data={this.reportData}
                        caption="Division pledge summary report"
                        visuallyHiddenCaption>
                    </Table>
                </GridItem>
            </GridWrapper>
        </>;
    }

    private downloadModel(): React.ReactNode {
        if(!this.state.showDownloadModel) {
            return <></>;
        }

        const CSVDownloader = this.props.CSVDownloader;
        const divisionsList: string[] = Array.from(new Set(this.props.report.divisionPledgeSummary.map(p => p.division)))
            .sort((a, b)=> a.localeCompare(b));
        const reportData = this.getExportData(this.state.selectedDivisions, this.props.report);

        return <Modal
            fullScreen
            restrictClose
            alert
            handleClose={this.closeDownloadModel.bind(this)}
            open={this.state.showDownloadModel}
            headingId="dialog-modal">
                <ModalHeading element="h3">Select divisions to download</ModalHeading>
                <TableContainer sortable responsive>
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell key="all-divisions">
                                <Checkbox
                                    name="all-divisions"
                                    inline
                                    label=""
                                    hideLabel
                                    checked={this.state.allDivisionsSelected}
                                    onChange={(e: any) => this.handleSelectAllRows(e.target.checked)} />
                            </TableHeaderCell>
                            <TableHeaderCell key="Division">Division</TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                    <TableBody>
                        {
                            divisionsList.map((division: string) =>{
                                return <TableRow>
                                    <TableCell lastWhenStacked className="ln-u-hard-ends">
                                        <Checkbox
                                                    name={`entry-${division}`}
                                                    inline
                                                    label={`Select ${division}`}
                                                    hideLabel
                                                    checked= {
                                                        this.state.selectedDivisions.find(x=>x === division)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e: any) =>
                                                        this.handleSelectRow(division, e.target.checked)
                                                    }/>
                                    </TableCell>
                                    <TableCell label={division}>{division}</TableCell>
                                </TableRow>;
                            })
                        }
                    </TableBody>
                </TableContainer>
                <ButtonGroupWrapper actionbar>                   
                    <ButtonGroupPrimary>
                        <OutlinedButton
                            className="ln-u-margin-right"
                            onClick={this.closeDownloadModel.bind(this)}>
                            Cancel
                        </OutlinedButton>
                        <CSVDownloader
                            bom={true}
                            filename={"DivisionSummaryReport"}
                            data={reportData}>
                            Download
                        </CSVDownloader>
                    </ButtonGroupPrimary>
                </ButtonGroupWrapper>
            </Modal>;
    }

    private closeDownloadModel() {
        this.setState({
            showDownloadModel: false
        });
    }

    private handleSelectAllRows(checked: boolean) {
        if(checked) {
            this.setState({
                selectedDivisions: Array.from(new Set(this.props.report.divisionPledgeSummary.map(p => p.division))),
                allDivisionsSelected: true
            });
        } else {
            this.setState({
                selectedDivisions: [],
                allDivisionsSelected: false
            });
        }
    }

    private handleSelectRow(id: string, selected: any) {
        const data = this.props.report.divisionPledgeSummary;
        let selectedRows = this.state.selectedDivisions;
        const selectedRow = data?.find(x=>x.division === id);
        if(selected && selectedRow) {
            selectedRows.push(selectedRow.division);
        } else if(!selected && selectedRow) {
            selectedRows = selectedRows.filter(x=>x !== id);
        }

        this.setState({
            selectedDivisions: selectedRows,
            allDivisionsSelected: data.length === selectedRows.length
        });
    }

    private getExportData(selectedDivisions: string[], report: IDivisionSummaryReport): any {
        let data:any[] = [];
        
        let totalData: IDivisionSummaryData = {}
        totalData["Division"] = "Total";
        totalData["Total Mandatory Pledges"] = 0;
        totalData["Number of colleagues pledged"] = 0;
        totalData["No. of colleagues who meet minimum requirement"] = 0;
        totalData["Exempted Colleagues"] = 0;
        totalData["% of colleagues pledged mandatory shifts or exempt"] = 0;
        totalData["Total Colleagues"] = 0;
        
        const selectedDivisionSummary = report.divisionPledgeSummary.filter(x=>selectedDivisions.includes(x.division))
        selectedDivisionSummary.forEach((ds: IDivisionPledgeSummary)=>{
            let divisionData: IDivisionSummaryData = {};

            divisionData["Division"] = ds.division;

            const divsionDateData = report.pledgesByDate.filter(pd=> pd.division === ds.division);
            let totalPledges = 0;
            divsionDateData.forEach(dd => {
                const columnName = this.formatDateColumnHeader(dd.eventDate);
                divisionData[columnName] = dd.pledgingsCount;
                if(totalData[columnName]) {
                    totalData[columnName] += dd.pledgingsCount;
                } else {
                    totalData[columnName] = dd.pledgingsCount;
                }

                totalPledges += dd.pledgingsCount;
            });

            divisionData["Total Mandatory Pledges"] = totalPledges;
            totalData["Total Mandatory Pledges"] += totalPledges;
            divisionData["Number of colleagues pledged"] = ds.colleaguePledgesCount;
            totalData["Number of colleagues pledged"] += ds.colleaguePledgesCount;
            divisionData["No. of colleagues who meet minimum requirement"] = ds.totalMetRequirement - ds.totalExempted;
            totalData["No. of colleagues who meet minimum requirement"] += ds.totalMetRequirement - ds.totalExempted;

            divisionData["Exempted Colleagues"] = ds.totalExempted;
            totalData["Exempted Colleagues"] += ds.totalExempted;
            const percentage = ((ds.totalMetRequirement / ds.totalProfiles) * 100).toFixed(2);
            divisionData["% of colleagues pledged mandatory shifts or exempt"] = percentage;
            totalData["% of colleagues pledged mandatory shifts or exempt"] += parseFloat(percentage);
            divisionData["Total Colleagues"] = ds.totalProfiles;
            totalData["Total Colleagues"] += ds.totalProfiles;
            
            data.push(divisionData);
        });

        data = data.sort((a, b) => a["Division"] && a["Division"].localeCompare(b["Division"]));
        totalData["% of colleagues pledged mandatory shifts or exempt"] = (totalData["% of colleagues pledged mandatory shifts or exempt"] / data.length).toFixed(2);
        data.push(totalData);

        // pledge by day percentage data
        let percentageData: IDivisionSummaryData = {};
        percentageData["Division"] = "Pledges by day as %";
        if(report.divisionPledgeSummary.length > 0) {
            const firstItem = report.divisionPledgeSummary[0];
            const divsionDateData = report.pledgesByDate.filter(pd=> pd.division === firstItem.division);
            divsionDateData.forEach(dd => {
                const columnName = this.formatDateColumnHeader(dd.eventDate);
                if(totalData["Total Mandatory Pledges"] > 0) {
                    percentageData[columnName] = ((totalData[columnName] / totalData["Total Mandatory Pledges"]) * 100).toFixed(2);
                } else {
                    percentageData[columnName] = 0;
                }
            });
        }

        percentageData["Total Mandatory Pledges"] = "";
        percentageData["Number of colleagues pledged"] = "";
        percentageData["No. of colleagues who meet minimum requirement"] = "";
        percentageData["Exempted Colleagues"] = "";
        percentageData["% of eligible colleagues pledged mandatory shifts"] = "";
        percentageData["Total Colleagues"] = "";        
        data.push(percentageData);

        return data;
    }

    private getReportTableData(report: IDivisionSummaryReport): any {
        let data: IDivisionSummaryData[] = [];
        
        let totalData: IDivisionSummaryData = {}
        totalData["division"] = "Total";
        totalData["pledgingsCount"] = 0;
        totalData["colleaguePledgesCount"] = 0;
        totalData["totalMetRequirement"] = 0;
        totalData["totalPercentageMetRequirement"] = 0;
        totalData["totalProfiles"] = 0;
        totalData["totalExemptions"] = 0;

        report.divisionPledgeSummary.forEach((ds: IDivisionPledgeSummary)=>{
            let divisionData: IDivisionSummaryData = {};

            const divsionDateData = report.pledgesByDate.filter(pd=> pd.division === ds.division);
            let totalPledges = 0;
            divsionDateData.forEach(dd => {
                const columnName = this.getDateColumnName(dd.eventDate);
                divisionData[columnName] = dd.pledgingsCount;
                if(totalData[columnName]) {
                    totalData[columnName] += dd.pledgingsCount;
                } else {
                    totalData[columnName] = dd.pledgingsCount;
                }
                
                totalPledges += dd.pledgingsCount;
            });

            divisionData["division"] = ds.division;
            divisionData["pledgingsCount"] = totalPledges;
            totalData["pledgingsCount"] += totalPledges;
            divisionData["colleaguePledgesCount"] = ds.colleaguePledgesCount;
            totalData["colleaguePledgesCount"] += ds.colleaguePledgesCount;
            divisionData["totalMetRequirement"] = ds.totalMetRequirement - ds.totalExempted;
            totalData["totalMetRequirement"] += ds.totalMetRequirement - ds.totalExempted;

            const percentage = ((ds.totalMetRequirement / ds.totalProfiles) * 100).toFixed(2);
            divisionData["totalPercentageMetRequirement"] = percentage;
            totalData["totalPercentageMetRequirement"] += parseFloat(percentage);
            divisionData["totalProfiles"] = ds.totalProfiles;
            totalData["totalProfiles"] += ds.totalProfiles;
            divisionData["totalExemptions"] = ds.totalExempted;
            totalData["totalExemptions"] += ds.totalExempted;

            data.push(divisionData);
        });

        data = data.sort((a, b) => a["division"] && a["division"].localeCompare(b["division"]));
        totalData["totalPercentageMetRequirement"] = (totalData["totalPercentageMetRequirement"] / data.length).toFixed(2);
        data.push(totalData);

        // pledge by day percentage data
        let percentageData: IDivisionSummaryData = {};
        percentageData["division"] = "Pledges by day as %";
        if(report.divisionPledgeSummary.length > 0) {
            const firstItem = report.divisionPledgeSummary[0];
            const divsionDateData = report.pledgesByDate.filter(pd=> pd.division === firstItem.division);
            divsionDateData.forEach(dd => {
                const columnName = this.getDateColumnName(dd.eventDate);
                if(totalData["pledgingsCount"] > 0) {
                    percentageData[columnName] = ((totalData[columnName] / totalData["pledgingsCount"]) * 100).toFixed(2);
                } else {
                    percentageData[columnName] = 0;
                }
            });
        }

        percentageData["pledgingsCount"] = "";
        percentageData["colleaguePledgesCount"] = "";
        percentageData["totalMetRequirement"] = "";
        percentageData["totalPercentageMetRequirement"] = "";
        percentageData["totalProfiles"] = "";
        percentageData["totalExemptions"] = "";
        data.push(percentageData);

        return data;
    }

    private getReportTableColumns(report: IDivisionSummaryReport): any[] {
        const columns: any[] = [];
        columns.push({
            name: "Division",
            accessor: (rowData: any) => ({ value: rowData.division }),
            hideLabel: true,
            className: "divisionsummary-division-col"
        });

        const dateValues: Date[] = [];

        // create date columns
        report.pledgesByDate.forEach((e: IDivisionPledgeByDateSummary) => {
            if(dateValues.filter(d=>d.toISOString() === e.eventDate.toISOString()).length === 0) {
                columns.push({
                    name: this.formatDateColumnHeader(e.eventDate),
                    accessor: (rowData: any) => ({ value: rowData[this.getDateColumnName(e.eventDate)] }),
                    hideLabel: true
                });

                dateValues.push(e.eventDate);
            }
        });

        columns.push({
            name: "Total Mandatory Pledges",
            accessor: (rowData: any) => ({ value: rowData.pledgingsCount }),
            hideLabel: true
        });

        columns.push({
            name: "Number of colleagues pledged",
            accessor: (rowData: any) => ({ value: rowData.colleaguePledgesCount }),
            hideLabel: true
        });

        columns.push({
            name: "No. of colleagues who meet minimum requirement",
            accessor: (rowData: any) => ({ value: rowData.totalMetRequirement }),
            hideLabel: true
        });

        columns.push({
            name: "Exemptions",
            accessor: (rowData: any) => ({ value: rowData.totalExemptions }),
            hideLabel: true
        });

        columns.push({
            name: "% of colleagues pledged mandatory shifts or exempt",
            accessor: (rowData: any) => ({ value: rowData.totalPercentageMetRequirement }),
            hideLabel: true
        });

        columns.push({
            name: "Total Colleagues",
            accessor: (rowData: any) => ({ value: rowData.totalProfiles }),
            hideLabel: true
        });

        return columns;
    }

    private formatDateColumnHeader(date: Date) {
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    }

    private getDateColumnName(date: Date) {
        return `dt_col_${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${date.getFullYear()}`;
    }
}
