import React from "react";
import { useNavigate, useParams } from "react-router";
import { PageModes } from "../PageModes";
import { Form, Fieldset, Legend, TextAreaField, TextInputField, FormOptionFieldList, Label, CheckboxField} from '@jsluna/form';
import { FilledButton, ButtonGroupWrapper, ButtonGroupPrimary, OutlinedButton, ButtonGroupSecondary } from '@jsluna/button';
import { Modal, ModalHeading } from '@jsluna/modal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AuthorisationRoutes, EventRoutes } from "../../Routes";
import { ICreateEvent } from "../../models/events/ICreateEvent";
import { IUpdateEvent } from "../../models/IUpdateEvent";
import { Notification, NotificationTypes } from "../Common/Notification";
import { IShiftTimeItem, ShiftTimings } from "./ShiftTimings";
import { IShiftTiming } from "../../models/events/IShiftTiming";
import { IBasePageProps } from "../IBasePageProps";
import { useServices } from "../../providers/hooks/useServices";
import { GetStoreTypesLabel } from "./Common";

const dateNames = {
    startDate: "eventStartDate",
    endDate: "eventEndDate",
    liveDate: "eventLiveDate"
}

interface IError {
    [key:string]: string;
}

interface IEventFormProps extends IBasePageProps {
    mode: PageModes;
    id?: number;
}

interface IEventFormState {
    eventInfo: IEvent;
    errors: IError;
    isDelModalOpen: boolean;
    loading: boolean,
    displayError: boolean;
    minEndDate?: Date;
    maxLiveDate?: Date;
    shiftTimings: IShiftTimeItem[];
}

interface IEvent {
    eventName?: string;
    maxPledges? : string;
    storeTypes: number[];
    selectedStores?: string[];
    eventStartDate? : Date;
    eventEndDate? : Date;
    eventLiveDate? : Date;
    minPledgeDays?: string;
}

class EventForm extends React.Component<IEventFormProps, IEventFormState> {
    constructor(props: IEventFormProps) {
       super(props);

        this.state = {
            eventInfo: {
                eventStartDate: new Date(),
                storeTypes: []
            },
            shiftTimings: [
                {id: 1, isFullDay: true}, 
                {id: 2, isFullDay: true}, 
                {id: 3, isFullDay: true}, 
                {id: 4, isFullDay: true}, 
                {id: 5, isFullDay: true},
                {id: 6, isFullDay: true},
                {id: 7, isFullDay: true},
                {id: 8, isFullDay: true},
                {id: 9, isFullDay: true},
                {id: 10, isFullDay: true}],
            errors: {},
            isDelModalOpen: false,
            loading: true,
            displayError: false,
            minEndDate: new Date(),
            maxLiveDate: new Date()
       };

       this.onTextChange = this.onTextChange.bind(this);
       this.onNumberChange = this.onNumberChange.bind(this);
       this.onDateChange = this.onDateChange.bind(this);
       this.createEvent = this.createEvent.bind(this);
       this.updateEvent = this.updateEvent.bind(this);
    }

    public render(): React.ReactNode {

        if(this.state.loading) {
            return <Notification type={NotificationTypes.Progress} display={true} onClose={()=>{}}/>
        }
        if(this.state.displayError) {
            return <Notification type={NotificationTypes.Error} 
            display={true} 
            displayMessage= "Unable to process your request"
            onClose={()=>{
                this.setState({
                  displayError: false
                });
            }}/>
        }
        return <>
        <Form className="o-form">
            <Fieldset>
                <Legend fontStyle="h2" className='topHeaderPadding'>Event details</Legend>
                <TextInputField
                    name="eventName"
                    label="Name"
                    error={this.state.errors["eventName"]}
                    info="Name of the event"
                    required
                    value={this.state.eventInfo.eventName}
                    onChange={this.onTextChange.bind(this)} />
                <TextInputField
                    name="maxPledges"
                    label="Maximum pledges"
                    error={this.state.errors["maxPledges"]}
                    info="Add number of maximum pledges per store"
                    max={100}
                    maxLength={3}
                    min={1}
                    type="number"
                    required
                    value={this.state.eventInfo.maxPledges}
                    onChange={this.onNumberChange.bind(this)} /> 
                <TextInputField
                    name="minPledgeDays"
                    label="Minimum pledge days"
                    error={this.state.errors["minPledgeDays"]}
                    info="Specify miniumum number of mandatory colleague pledge days for the event"
                    max={10}
                    maxLength={2}
                    min={0}
                    type="number"
                    required
                    value={this.state.eventInfo.minPledgeDays}
                    onChange={this.onNumberChange.bind(this)} />

                <div className={`ln-c-form-group${this.state.errors["eventStartDate"] !== undefined ? " has-error":""}`}>
                    <label className="ln-c-label">Start Date</label>
                    <div className="ln-c-field-info ln-c-field-info--extra">The date when the event will start</div>
                    <div className='ln-c-input-group'>
                        <DatePicker name="eventStartDate" 
                        onChange={(date:Date)=>this.onDateChange(dateNames.startDate, date)} 
                        minDate={new Date()}
                        selected={this.state.eventInfo.eventStartDate} 
                        dateFormat='dd/MM/yyyy'/>                    
                    </div>
                    {
                        this.state.errors["eventStartDate"] !== undefined &&
                        <div id="eventNameValidation" className="ln-c-field-info ln-c-field-info--error" role="alert" aria-live="assertive">
                            { this.state.errors["eventStartDate"] }
                        </div>
                    }
                </div>    

                <div className={`ln-c-form-group${this.state.errors["eventEndDate"] !== undefined ? " has-error":""}`}>
                    <label className="ln-c-label">End Date</label>
                    <div className="ln-c-field-info ln-c-field-info--extra">The date when the event will finish</div>
                    <div className='ln-c-input-group'>
                        <DatePicker name="eventEndDate"
                            onChange={(date:Date)=>this.onDateChange(dateNames.endDate, date)} 
                            minDate={this.state.minEndDate}
                            selected={this.state.eventInfo.eventEndDate} 
                            dateFormat='dd/MM/yyyy'/>
                    </div>
                    {
                        this.state.errors["eventEndDate"] !== undefined &&
                        <div className="ln-c-field-info ln-c-field-info--error" role="alert" aria-live="assertive">
                            { this.state.errors["eventEndDate"] }
                        </div>
                    }
                </div>

                <div className={`ln-c-form-group${this.state.errors["eventLiveDate"] !== undefined ? " has-error":""}`}>
                    <label className="ln-c-label">Live Date</label>
                    <div className="ln-c-field-info ln-c-field-info--extra">The date when the event will be live</div>
                    <div className='ln-c-input-group'>
                        <DatePicker 
                            name="eventLiveDate"
                            onChange={(date:Date)=>this.onDateChange(dateNames.liveDate, date)}
                            minDate={new Date()}
                            maxDate={this.state.maxLiveDate}
                            selected={this.state.eventInfo.eventLiveDate} 
                            dateFormat='dd/MM/yyyy'/>
                    </div>
                    {
                        this.state.errors["eventLiveDate"] !== undefined &&
                        <div id="eventNameValidation" className="ln-c-field-info ln-c-field-info--error" role="alert" aria-live="assertive">
                            { this.state.errors["eventLiveDate"] }
                        </div>
                    }
                </div>
                {
                    this.props.mode === PageModes.New &&
                        <ShiftTimings
                            shiftTimings={this.state.shiftTimings}
                            onShiftChange={this.onShiftTimeChange.bind(this)}
                            onFullShiftChange={this.onFullShiftChange.bind(this)}
                            error={this.state.errors["shiftTimings"]}/>
                }
                {
                    (this.props.mode === PageModes.New) && 
                        <CheckboxField 
                            onClick={this.showSelectedStoresEntry.bind(this)}
                            label="Apply event to"
                            name="eventAppliesTo"
                            info="Specify which stores the event applies to"
                            fullWidth
                            outlined
                            error={this.state.errors["storeTypes"]}
                            options={this.getOptions()} />
                }
                {
                    this.props.mode === PageModes.Edit &&
                        <><Label htmlFor="eventAppliesTo">Apply event to</Label><br />{GetStoreTypesLabel(this.state.eventInfo.storeTypes)}<br/><br/></>
                }
                {
                    (this.state.eventInfo.storeTypes !== undefined && this.state.eventInfo.storeTypes.find(x=> x === 4)) &&
                        <TextAreaField 
                            name="selectedStores" 
                            label="Add individual stores codes, seperated by comma" 
                            value={this.state.eventInfo.selectedStores?.join(',')} 
                            onChange={this.onSelectedStoresChange.bind(this)}
                            error={this.state.errors["selectedStores"]}
                            readOnly={this.props.mode === PageModes.Edit? true: false}/>
                }
            
                {
                    (this.props.mode === PageModes.New) &&
                        <ButtonGroupWrapper className="ln-u-hard-top ln-u-push-bottom">
                            <ButtonGroupPrimary>
                                <FilledButton onClick={this.createEvent}>
                                    Create event
                                </FilledButton>
                            </ButtonGroupPrimary>
                        </ButtonGroupWrapper> 
                }
                { 
                    (this.props.mode === PageModes.Edit) &&
                        <ButtonGroupWrapper>
                            <ButtonGroupPrimary className='btnRightOnPopup'>
                                <FilledButton onClick={this.updateEvent.bind(this)}>
                                    Save changes
                                </FilledButton>
                            </ButtonGroupPrimary>
                            <ButtonGroupSecondary className='btnLeftOnPopup'>
                                <button type="button" onClick={this.openModalbox.bind(this)} className="ln-c-button ln-c-button--outlined btRightOnPopup">
                                    Delete event
                                </button>
                            </ButtonGroupSecondary>
                        </ButtonGroupWrapper>
                }
            </Fieldset>           
            <Modal
                fullScreen
                restrictClose
                alert
                handleClose={this.closeModalbox.bind(this)}
                open={this.state.isDelModalOpen}
                headingId="dialog-modal">
                <ModalHeading element="h3">An action is required</ModalHeading>
                    <p>
                        Are you sure to delete the event? 
                        <br/>
                        <br/>
                        <h4>Event Name - {this.state.eventInfo.eventName}</h4>
                    </p>
                <ButtonGroupWrapper actionbar>                   
                    <ButtonGroupPrimary>
                        <OutlinedButton
                            className="ln-u-margin-right"
                            onClick={this.closeModalbox.bind(this)}>
                            Cancel
                        </OutlinedButton>
                        <FilledButton onClick={this.deleteEvent.bind(this)}>Accept</FilledButton>
                    </ButtonGroupPrimary>
                </ButtonGroupWrapper>
            </Modal>
        </Form>
        </>
    }

    private onShiftTimeChange(id:number, value: string) {
        let shiftItems = this.state.shiftTimings;
        let item = shiftItems
            .find(s=>s.id === id);
        if(item !== undefined) {
            item.value = value;
            this.setState({
                shiftTimings: shiftItems
            });
        }
    }

    private onFullShiftChange(id:number, value: boolean) {
        let shiftItems = this.state.shiftTimings;
        let item = shiftItems
            .find(s=>s.id === id);
        if(item !== undefined) {
            item.isFullDay = value;
            this.setState({
                shiftTimings: shiftItems
            })
        }
    }

    public async componentDidMount() {
        try {
            if(this.props.mode === PageModes.Edit && this.props.id !== undefined) {
                // check if user have permission
                const isAdmin = await this.props.services.eventManagementService.isAdmin();
                if(!isAdmin) {
                    this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
                }

                const selEvent = await this.props.services.eventManagementService.getEventById(this.props.id);
                if(selEvent !== undefined){
                    this.setState({
                        eventInfo: {
                            eventName: selEvent.name,
                            maxPledges: selEvent.maxPledges.toString(),
                            minPledgeDays: selEvent.minPledgeDays.toString(),
                            eventStartDate: selEvent.startDate,
                            eventEndDate: selEvent.endDate,
                            eventLiveDate: selEvent.liveDate,
                            storeTypes: selEvent.storeTypes,
                            selectedStores: selEvent.stores
                        },
                        maxLiveDate: selEvent.startDate
                    });
                }      
            } else {
                const isAdmin = await this.props.services.eventManagementService.isAdmin();
                if(!isAdmin) {
                    this.props.navigate(AuthorisationRoutes.UnauthorisedPage);
                }
            }

            this.setState({
                loading: false,
                displayError : false
            });
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true
            });
        }      
    }

    private getOptions(): FormOptionFieldList {
        let options: FormOptionFieldList = [
            { value: "1", label: "Supermarkets" },
            { value: "2", label: "All SiS" },
            { value: "3", label: "All SaS" },
            { value: "6", label: "LFC"},
            { value: "7", label: "Supermarket Collection Points"},
            { value: "8", label: "Convenience Collection Points"},
            { value: "4", label: "Specific Stores"}
        ];

        if(this.state.eventInfo !== undefined
            && this.state.eventInfo.storeTypes !== undefined) {
            const selectedOption = this.state.eventInfo.storeTypes;
            options.forEach((option) => {
                if(option.value === selectedOption) option.defaultChecked = true;
            });
        }

        return options;
    }

    private onDateChange(name: string, e: any) {

        let errors = this.state.errors;
        if(e === undefined || e === null) {
            errors[name] = "This information is required.";
        } else if(errors[name] !== undefined) {
            delete errors[name];
        }

        let minEndDate = new Date();
        let maxLiveDate = new Date();
        if(name === dateNames.startDate) {
            minEndDate = e;
            maxLiveDate = e;
            this.setState({
                eventInfo: {
                    ...this.state.eventInfo,
                    [name]: e,
                    eventEndDate: undefined,
                    eventLiveDate: undefined
                },
                errors: errors,
                minEndDate: minEndDate,
                maxLiveDate: maxLiveDate
            });
        } else {
            this.setState({
                eventInfo: {
                    ...this.state.eventInfo,
                    [name]: e
                },
                errors: errors
            });
        }
    }

    private onTextChange(e: any) {
        this.setEventInfoState(e.target.name, e.target.value);
    }

    private onSelectedStoresChange(e: any) {
        if(e.target.value !== undefined) {
            const value = e.target.value.split(',');
            if(value?.toString()?.trim().length === 0){
                this.setEventInfoState(e.target.name, undefined);
            }
            else{
                this.setEventInfoState(e.target.name, value);
            }
        }
    }

    private onNumberChange(e: any) {
        if(e.target.value !== undefined && e.target.value !== "") {
            if(isNaN(e.target.value)){
                this.setEventInfoState(e.target.name, "");                     
            } 
            else{
                this.setEventInfoState(e.target.name, e.target.value);  
            }  
        } else {
            this.setEventInfoState(e.target.name, undefined);
        }
    }

    private showSelectedStoresEntry(e: any) {
       let currentStoreTypes = this.state.eventInfo.storeTypes;
       if(e.target.checked) {
        currentStoreTypes.push(e.target.value);
       } else {
        currentStoreTypes = currentStoreTypes.filter(x=>x === e.target.value);
       }

       this.setState({
          eventInfo: {
            ...this.state.eventInfo,
            storeTypes: currentStoreTypes,
            selectedStores: []
          }
       });
    }

    private setEventInfoState(name: string, value: any) {
        let errors = this.state.errors;
        if(value === undefined || value === "" || value === null) {
            errors[name] = "This information is required.";
        } else if(errors[name] !== undefined) {
            delete errors[name];
        }

        this.setState({
            eventInfo: {
             ...this.state.eventInfo,
             [name]: value
            },
            errors: errors
        });
    }

    private async createEvent() {
        try {
            let validForm = true;
            let ename = this.state.eventInfo.eventName;
            let eselstores = this.state.eventInfo.selectedStores;
            const valMessage = "This information is requied";
            let errors: IError = {};
            if(this.state.eventInfo !== undefined) {
                if(this.state.eventInfo.eventName === undefined || ename?.trim().length === 0) {
                    validForm = false;
                    errors["eventName"] = valMessage;
                }

                if(this.state.eventInfo.maxPledges === undefined || this.state.eventInfo.maxPledges === "") {
                    validForm = false;
                    errors["maxPledges"] = valMessage;
                }

                if(this.state.eventInfo.minPledgeDays === undefined || this.state.eventInfo.minPledgeDays === "") {
                    validForm = false;
                    errors["minPledgeDays"] = valMessage;
                }

                if(this.state.eventInfo.eventStartDate === undefined){
                    validForm = false;
                    errors["eventStartDate"] = valMessage;
                }

                if(this.state.eventInfo.eventEndDate === undefined){
                    validForm = false;
                    errors["eventEndDate"] = valMessage;
                }

                if(this.state.eventInfo.eventLiveDate === undefined){
                    validForm = false;
                    errors["eventLiveDate"] = valMessage;
                }

                if(this.state.eventInfo.eventEndDate !== undefined && this.state.eventInfo.eventStartDate !== undefined && (this.state.eventInfo.eventEndDate < this.state.eventInfo.eventStartDate)){
                    validForm = false;
                    errors["eventEndDate"] = "End date should be greater than the start date";
                }

                if(this.state.eventInfo.eventStartDate !== undefined && this.state.eventInfo.eventLiveDate !== undefined && (this.state.eventInfo.eventLiveDate >= this.state.eventInfo.eventStartDate)){
                    validForm = false;
                    errors["eventLiveDate"] = "Live date should be less than the start date";
                }

                if(this.state.eventInfo.storeTypes === undefined || this.state.eventInfo.storeTypes.length === 0) {
                    validForm = false;
                    errors["storeTypes"] = "Select at least one store type";
                }

                if(this.state.eventInfo.storeTypes !== undefined && this.state.eventInfo.storeTypes.find(x=> x === 4)) {
                    if(this.state.eventInfo.selectedStores === undefined || eselstores?.toString()?.trim().length === 0){
                        validForm = false;
                        errors["selectedStores"] = valMessage;
                    }
                }

                if(this.state.shiftTimings.length === 0 || this.state.shiftTimings.filter(x=>!x.value).length === this.state.shiftTimings.length) {
                    validForm = false;
                    errors["shiftTimings"] = "Specify at least one shift timing";
                }
            }

            if(!validForm) {
                this.setState({
                    errors: errors
                });
            }
            else {
                if(this.state.eventInfo.eventStartDate !== undefined && this.state.eventInfo.eventEndDate !== undefined && this.state.eventInfo.eventLiveDate !== undefined) {
                    const shiftTimings: IShiftTiming[] = [];
                    this.state.shiftTimings.forEach((t)=> {
                        if(t.value !== undefined && t.value.trim() !== '') {
                            shiftTimings.push({
                                shiftTiming: t.value,
                                isFullDayShift: t.isFullDay
                            });
                        }
                    });

                    const input: ICreateEvent = {
                        name: this.state.eventInfo.eventName ? this.state.eventInfo.eventName: "",
                        startDate: this.state.eventInfo.eventStartDate,
                        endDate: this.state.eventInfo.eventEndDate,
                        liveDate: this.state.eventInfo.eventLiveDate,
                        storeTypes: this.state.eventInfo.storeTypes,
                        maxPledges : this.state.eventInfo.maxPledges ? parseInt(this.state.eventInfo.maxPledges) : 0,
                        minPledgeDays: this.state.eventInfo.minPledgeDays ? parseInt(this.state.eventInfo.minPledgeDays) : 0,
                        stores: this.state.eventInfo.selectedStores,
                        shiftTimings: shiftTimings
                    };
                    
                    await this.props.services.eventManagementService.addEvent(input);

                    this.props.navigate(EventRoutes.EventSubmitted);
                } 
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                displayError : true
            });
        }   
    } 
    
    private async updateEvent() {
        try {
            let validForm = true;
            const valMessage = "This information is requied";   
            let errors: IError = {};
            let ename = this.state.eventInfo.eventName;
            if(this.state.eventInfo.eventName === undefined || ename?.trim().length === 0) {
                validForm = false;
                errors["eventName"] = valMessage;
            }

            if(this.state.eventInfo.maxPledges === undefined || this.state.eventInfo.maxPledges === ""){
                validForm = false;
                errors["maxPledges"] = valMessage;
            }

            if(this.state.eventInfo.minPledgeDays === undefined || this.state.eventInfo.minPledgeDays === "") {
                validForm = false;
                errors["minPledgeDays"] = valMessage;
            }

            if(this.state.eventInfo.eventStartDate === undefined){
                validForm = false;
                errors["eventStartDate"] = valMessage;
            }

            if(this.state.eventInfo.eventEndDate === undefined){
                validForm = false;
                errors["eventEndDate"] = valMessage;
            }

            if(this.state.eventInfo.eventLiveDate === undefined){
                validForm = false;
                errors["eventLiveDate"] = valMessage;
            }

            if(this.state.eventInfo.eventEndDate !== undefined && this.state.eventInfo.eventStartDate !== undefined && (this.state.eventInfo.eventEndDate < this.state.eventInfo.eventStartDate)){
                validForm = false;
                errors["eventEndDate"] = "End date should be greater than the start date";
            }

            if(this.state.eventInfo.eventStartDate !== undefined && this.state.eventInfo.eventLiveDate !== undefined && (this.state.eventInfo.eventLiveDate >= this.state.eventInfo.eventStartDate)){
                validForm = false;
                errors["eventLiveDate"] = "Live date should be less than the start date";
            }

            if(!validForm){
                this.setState({
                    errors: errors
                });
            }
            else {
                if(this.state.eventInfo.eventName !== undefined && this.state.eventInfo.eventStartDate !== undefined && this.state.eventInfo.eventEndDate !== undefined && this.state.eventInfo.eventLiveDate !== undefined) {
                    const input: IUpdateEvent = {
                        eventName: this.state.eventInfo.eventName,
                        startDate: this.state.eventInfo.eventStartDate, 
                        endDate: this.state.eventInfo.eventEndDate, 
                        liveDate: this.state.eventInfo.eventLiveDate, 
                        maxPledges : this.state.eventInfo.maxPledges ? parseInt(this.state.eventInfo.maxPledges) : 0,
                        minPledgeDays : this.state.eventInfo.minPledgeDays ? parseInt(this.state.eventInfo.minPledgeDays) : 0,
                    };
                   
                    if(this.props.id !== undefined){
                        await this.props.services.eventManagementService.updateEvent(this.props.id, input);
                        this.props.navigate(EventRoutes.EventUpdated);
                    }
                }
            }
        } catch (error) {
           console.log(error); 
           this.setState({
                loading: false,
                displayError : true
           });
        }        
    }

    private async deleteEvent() {
        try {
            
            this.setState({
                isDelModalOpen: false,
                loading: true
            });
            
            if(this.props.id !== undefined) {
                await this.props.services.eventManagementService.removeEvent(this.props.id);
                this.setState({
                    isDelModalOpen: false,
                    loading: false
                });

                this.props.navigate(EventRoutes.Events);
            }
        } catch (error) {
            console.log(error);
            this.setState({
                loading: false,
                isDelModalOpen: false,
                displayError : true
            });
        }        
    }

    private async openModalbox(){
        this.setState(
            {
                isDelModalOpen: true
            }
        );       
    }

    private closeModalbox(){
        this.setState({
            isDelModalOpen: false
        });
    }
}

export default function NewEventPage() {
    const navigate = useNavigate();    
    const services = useServices();
    return <EventForm mode={PageModes.New} navigate={navigate} services={services}/>
}

export function EditEventPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    let idValue: number = 0;
    if(id) {
        idValue = parseInt(id);
    }
    
    const services = useServices();

    return <EventForm mode={PageModes.Edit} id={idValue} navigate={navigate} services={services}/>
}